<template>
  <BOverlay
    variant="light"
    :show="loading.detail"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <BCard>
      <div class="flex mb-3">
        <BButton
          variant="primary"
          size="sm"
          class="rounded-lg mr-2"
          @click="handleBack"
        >
          <b-icon-chevron-left />
        </BButton>
        <span class="text-[24px] font-semibold my-auto text-black">Buat Campaign - Blast Email</span>
      </div>
      <BButton
        v-for="btn in buttonData"
        :key="btn.id"
        style="border-radius: 25px"
        class="mr-1"
        :variant="activeBtn === btn.id ? 'primary' : 'outline-primary'"
        @click="handleActiveButton(btn.id)"
      >
        {{ btn.label }}
      </BButton>
      <ValidationObserver #default="{ invalid }">
        <div class="d-flex justify-content-end mb-1">
          <BButton
            class="rounded-lg"
            :variant="invalid || loading.draft ? 'outline-secondary' : 'outline-primary'"
            :disabled="invalid || loading.draft"
            size="sm"
            @click="prevent=onDraft()"
          >
            <div class="flex items-center">
              <BSpinner
                v-if="loading.draft"
                variant="primary"
                small
                class="mr-1"
              />
              <span>Simpan Draft</span>
            </div>
          </BButton>
          <BButton
            class="rounded-lg mx-1"
            :variant="invalid ? 'secondary' : 'primary'"
            :disabled="invalid"
            size="sm"
            @click.prevent="$bvModal.show('modal-publish')"
          >
            <div class="flex items-center">
              <span class="k-send-2 w-14 text-2xl" />
              <span>Publish Sekarang</span>
            </div>
          </BButton>
        </div>
        <BRow>
          <BCol :lg="activeBtn === 1 ? 4 : 6">
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Email Pengirim</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <v-select
                  v-model="email"
                  label="email"
                  :options="optionSenderEmails"
                  :reduce="option => option"
                  placeholder="Ketik untuk mencari..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol :lg="activeBtn === 1 ? 4 : 6">
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Target Pengguna</span>
                  <span class="text-primary">*</span>
                  <span class="ml-[5px]">({{ totalTargets }})</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Target pengguna"
                rules="required"
              >
                <BRow>
                  <BCol lg="9">
                    <div
                      class="border p-[5px] gap-5 rounded d-flex flex-wrap align-items-center"
                      style="min-height: 38px; border-color: #d8d6de !important;"
                    >
                      <span
                        v-if="totalTargets === 0"
                        class="text-secondary ml-[8px] text-nowrap overflow-hidden"
                        style="text-overflow: ellipsis;"
                      >Gunakan filter untuk memilih...</span>
                      <div
                        v-for="(items, index) in selectedProd"
                        :key="index"
                        class="d-flex justify-content-center align-items-center bg-red rounded px-[5px] py-[3px]"
                      >
                        <img
                          :src="items.logo"
                          width="20"
                        >
                        {{ handleLableName(items.product_name) }} ({{ items.count }})
                        <b-icon
                          icon="x"
                          font-scale="1.5"
                          class="ml-[5px]"
                          style="cursor: pointer"
                          @click="deleteProduct(null, items)"
                        />
                      </div>
                    </div>
                  </BCol>
                  <BCol lg="3">
                    <BButton
                      id="optionsTarget"
                      variant="outline-primary"
                      size="sm"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/sort.svg"
                        alt="Komerce"
                        width="20"
                      >
                    </BButton>
                    <BPopover
                      target="optionsTarget"
                      triggers="focus"
                      placement="bottom"
                    >
                      <div
                        v-for="(item, idx) in optionsTarget"
                        :key="idx"
                      >
                        <div
                          class="d-flex"
                        >
                          <BFormCheckbox
                            v-model="item.targets[0].checked"
                            :indeterminate="indeterminate"
                            @change="handleSelectedTarget(item.targets[0].value, item.targets[0].checked, item.product_logo)"
                          />
                          <div
                            :key="idx"
                            v-b-toggle="`collapse-${handleProductName(item.product_name)}`"
                            class="flex items-center mb-[10px] gap-5"
                          >
                            <img
                              :src="item.product_logo"
                              alt="Komerce"
                              width="20"
                              height="20"
                            >
                            <div class="text-black">
                              {{ handleLableName(item.targets[0].label) }}
                            </div>
                            <div>
                              ({{ item.targets[0].count }})
                            </div>
                            <feather-icon
                              v-if="item.targets.length > 1"
                              icon="ChevronDownIcon"
                              class="when-closed"
                            />
                            <feather-icon
                              v-if="item.targets.length > 1"
                              icon="ChevronUpIcon"
                              class="when-opened"
                            />
                          </div>
                        </div>
                        <BCollapse :id="`collapse-${handleProductName(item.product_name)}`">
                          <div
                            v-for="(p, ids) in item.targets"
                            :key="ids"
                          >
                            <template v-if="ids !== 0">
                              <div class="flex items-cnter gap-5 ml-1 mb-[10px]">
                                <BFormCheckbox
                                  v-model="p.checked"
                                  @change="handleSelectedTarget(p.value, p.checked, item.product_logo)"
                                />
                                <div class="text-black">
                                  {{ handleLableName(p.label) }} ({{ p.count }})
                                </div>
                              </div>
                            </template>
                          </div>
                        </BCollapse>
                      </div>
                    </BPopover>
                  </BCol>
                </BRow>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            v-if="activeBtn === 1"
            lg="4"
          >
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Pilihan Template</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Pilihan Template"
                rules="required"
              >
                <v-select
                  v-model="template"
                  label="template"
                  :options="optionsTemplates"
                  :reduce="option => option"
                  placeholder="Ketik untuk mencari..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
        </BRow>
        <BRow>
          <BCol lg="12">
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Judul Campaign</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Judul Campaign"
                rules="required"
              >
                <BFormInput
                  v-model="title"
                  placeholder="Silahkan isi judul campaign"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
        </BRow>
        <BRow>
          <BCol lg="12">
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Isi Campaign</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Isi Campaign"
                rules="required"
              >
                <quill-editor
                  ref="quillEdit"
                  v-model="content"
                  :options="editorOptions"
                  toolbar="#my-toolbar"
                />
                <input
                  id="getFile"
                  type="file"
                  class="d-none"
                  @change="uploadFunction($event)"
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
        </BRow>
      </ValidationObserver>
      <ModalPublish
        :payload="payload"
        :email-sender="email"
        :title="title"
        :total-targets="totalTargets"
      />
      <ModalWarning />
    </BCard>
  </BOverlay>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import { newAxiosIns } from '@/libs/axios'
import isEmpty from 'lodash/isEmpty'
import ModalPublish from './ModalPublish.vue'
import ModalWarning from './ModalWarning.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    quillEditor,
    ModalPublish,
    ModalWarning,
  },
  data() {
    return {
      activeBtn: 1,
      required,
      buttonData: [
        { id: 1, label: 'Email Template' },
        { id: 2, label: 'Email Standar' },
      ],
      email: '',
      template: '',
      checkTarget: false,
      target: [],
      products: [],
      selectedProd: [],
      optionsTarget: [],
      optionsTemplates: [],
      optionSenderEmails: [],
      totalTargets: 0,
      targetItems: [],
      title: '',
      isEmpty,
      idCampaign: this.$route.params.id,
      selectedTarget: [],
      allSelected: false,
      indeterminate: false,
      loading: {
        info: false,
        draft: false,
      },
      content: '',
      contentFix: '',
      id: this.$route.params.id,
      dataDraft: {},
      editorOptions: {
        direction: 'ltr',
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline'], ['link'], ['image']],
            handlers: {
              image() {
                document.getElementById('getFile').click()
              },
            },
          },
        },
      },
      komplaceProds: [],
      komshipProds: [],
      komcardsProds: [],
      komtimProds: [],
      roProds: [],
    }
  },
  computed: {
    payload() {
      const payloadTemplate = {
        sender_email: this.email,
        service_type: this.target,
        title: this.title,
        content: this.contentFix,
        chosen_template: this.template,
      }

      const payloadStandard = {
        sender_email: this.email,
        service_type: this.target,
        title: this.title,
        content: this.contentFix,
      }

      return this.activeBtn === 1 ? payloadTemplate : payloadStandard
    },
  },
  watch: {
    selectedProd(v) {
      this.totalTargets = v.reduce((acc, item) => acc + item.count, 0)
      this.target = v.map(item => item.value)
      const logo = v.map(item => item.logo)

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.target.length; index++) {
        const target = this.optionsTarget.find(item => item.product_child === logo[0])?.targets.find(prod => prod.value === this.target[index])
        if (target) target.checked = true
      }
    },
    content() {
      const container = document.createElement('div')
      container.innerHTML = this.content

      container.querySelectorAll('img').forEach(img => {
        // eslint-disable-next-line no-param-reassign
        img.style.maxWidth = '100%'
      })

      this.contentFix = container.innerHTML
        .replaceAll('<strong>', '<span style="font-weight: 600">')
        .replaceAll('</strong>', '</span>')
        .replaceAll('</p>', '<br>')
        .replaceAll('<p>', '')
        .replaceAll('<p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">', '')
        .replaceAll('<br><br><br>', '<br><br>')
        .replaceAll('<br><br><br><br>', '<br><br><br>')
    },
  },
  mounted() {
    this.getListProducts()
  },
  methods: {
    extractProductLabels(productName) {
      const product = this.optionsTarget.find(item => item.product_name === productName)
      return product ? product.targets.map(t => t.value) : []
    },
    async getListProducts() {
      this.loading.info = true
      const url = '/sender/api/v1/email/get-info'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data

          this.optionsTarget = data.option_targets.map(item => ({
            ...item,
            checked: false,
            product_logo: item.product_logo.find(p => p.type === 'default').url,
            product_child: item.product_logo.find(p => p.type === 'white').url,
            targets: item.targets.map(t => ({
              ...t,
              checked: false,
              value: t.label,
              label: t.label.replace(/_/g, ' ')
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' '),
            })),
          }))

          this.optionsTemplates = data.choice_template
          this.optionSenderEmails = data.sender_email
          this.products = this.optionsTarget.flatMap(item => item.targets.map(target => ({
            ...target,
            product_name: target.label,
            logo: item.product_child,
            logo_default: item.product_logo,
          })))
        })

      this.komplaceProds = this.extractProductLabels('Komplace')
      this.komshipProds = this.extractProductLabels('Komship')
      this.komcardsProds = this.extractProductLabels('Komcards')
      this.komtimProds = this.extractProductLabels('Komtim')
      this.roProds = this.extractProductLabels('Raja Ongkir')

      if (this.id) this.getDataDraft()
    },
    async getDataDraft() {
      this.loading = true
      const url = `/sender/api/v1/campaign/get-detail/${this.id}`
      try {
        const res = await newAxiosIns.get(url)
        const { data } = res.data
        this.dataDraft = data
        this.email = data.sender_email
        this.template = data.chosen_template
        this.title = data.title
        this.content = data.content

        if (data.type_email === 'Email Standard') this.activeBtn = 2

        this.selectedProd = []

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < data.service_type.length; index++) {
          const service = data.service_type[index]

          const targets = this.optionsTarget.flatMap(item => item.targets.filter(value => value.value === service))
          if (targets) targets[0].checked = true

          this.selectedProd.push(...targets.map(target => ({
            ...target,
            product_name: service.replace(/_/g, ' ')
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' '),
            logo: this.optionsTarget.find(option => option.targets.some(t => t.value === service)).product_child,
            logo_default: this.optionsTarget.find(option => option.targets.some(t => t.value === service)).product_logo,
          })))
        }
      } finally {
        this.loading = false
      }
    },
    async onDraft() {
      const url = '/sender/api/v1/email/save-draft'
      await newAxiosIns.post(url, this.payload)
        .then(() => {
          this.$toast_success({ message: 'Draft campaign berhasil disimpan' })
          this.$router.push('/campaign/blast-email')
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal blast email' })
        })
    },
    async uploadFunction(e) {
      const form = new FormData()
      form.append('resource', e.target.files[0])
      const url = '/sender/api/v1/resource/upload'
      await newAxiosIns.post(url, form)
        .then(res => {
          const range = this.$refs.quillEdit.quill.getSelection()
          this.$refs.quillEdit.quill.insertEmbed(range.index, 'image', `${res.data.data.url}`)
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal melampirkan gambar' })
        })
    },
    handleActiveButton(id) {
      this.activeBtn = id
    },
    handleBack() {
      if (this.email === ''
      && isEmpty(this.target)
      && this.template.label === undefined
      && this.title === ''
      && this.content === '') {
        this.$router.go(-1)
      } else {
        this.$bvModal.show('modal-warning')
      }
    },
    handleSelectedTarget(v, c, i) {
      const target = this.optionsTarget.find(item => item.product_logo === i)?.targets

      // eslint-disable-next-line no-shadow
      const handleTarget = (prodArray, v, c) => {
        const targetProd = target.find(prod => prodArray.includes(prod.value))
        if (targetProd) {
          prodArray.forEach(targetType => {
            const foundTarget = target.find(t => t.value === targetType)
            if (foundTarget) foundTarget.checked = c
          })
        }
      }
      // eslint-disable-next-line no-shadow
      const handleUncheck = (prodArray, v, c) => {
        const targetProd = target.find(prod => prodArray.includes(prod.value))
        if (targetProd) {
          prodArray.forEach(targetType => {
            const foundTarget = target.find(t => t.value === targetType)
            if (foundTarget) foundTarget.checked = false
          })
        }
      }

      if (v === 'komplace') {
        handleTarget(this.komplaceProds, v, c)
      } if (v === 'komship') {
        handleTarget(this.komshipProds, v, c)
      } if (v === 'komcards') {
        handleTarget(this.komcardsProds, v, c)
      } if (v.startsWith('komplace_')) {
        handleUncheck(['komplace'], v, c)
      } if (v.startsWith('komship_')) {
        handleUncheck(['komship'], v, c)
      } if (v.startsWith('komcards_')) {
        handleUncheck(['komcards'], v, c)
      } if (v === 'raja_ongkir') {
        handleTarget(this.roProds, v, c)
      } if (v.startsWith('raja_ongkir_')) {
        handleUncheck(['raja_ongkir'], v, c)
      } if (v === 'komtim') {
        handleTarget(this.komtimProds, v, c)
      } if (v.startsWith('komtim_')) {
        handleUncheck(['komtim'], v, c)
      }

      const selectedProducts = this.optionsTarget.flatMap(item => item.targets.filter(prod => prod.checked).map(prod => ({
        product_name: prod.label,
        logo: item.product_child,
        value: prod.value,
        count: prod.count,
        checked: prod.checked,
      })))

      const filterSelected = selectedProducts.filter(item => {
        if (item.value === 'komplace') {
          return true
        } if (item.value.startsWith('komplace_')) {
          if (selectedProducts.find(prod => prod.value === 'komplace')) {
            return false
          }
          return true
        }
        if (item.value === 'komship') {
          return true
        } if (item.value.startsWith('komship_')) {
          if (selectedProducts.find(prod => prod.value === 'komship')) {
            return false
          }
          return true
        }
        if (item.value === 'komcards') {
          return true
        } if (item.value.startsWith('komcards_')) {
          if (selectedProducts.find(prod => prod.value === 'komcards')) {
            return false
          }
          return true
        }
        if (item.value === 'raja_ongkir') {
          return true
        } if (item.value.startsWith('raja_ongkir_')) {
          if (selectedProducts.find(prod => prod.value === 'raja_ongkir')) {
            return false
          }
          return true
        }
        if (item.value === 'komtim') {
          return true
        } if (item.value.startsWith('komtim_')) {
          if (selectedProducts.find(prod => prod.value === 'komtim')) {
            return false
          }
          return true
        }
        return true
      })

      this.selectedTarget = filterSelected
      this.selectedProd = filterSelected
      this.target = filterSelected.map(item => item.value)
      this.totalTargets = filterSelected.reduce((acc, item) => acc + item.count, 0)
    },
    deleteProduct(idx, p) {
      // eslint-disable-next-line no-shadow
      const target = this.optionsTarget.find(item => item.product_child === p.logo)?.targets.find(target => target.value === p.value)
      if (target) target.checked = false

      const targetIndex = this.selectedTarget.findIndex(item => item.value === target.value)

      if (targetIndex !== -1) {
        this.selectedTarget.splice(targetIndex, 1)
      }

      const prodIndex = this.selectedProd.findIndex(prod => prod.value === p.value)

      if (prodIndex !== -1) {
        this.selectedProd.splice(prodIndex, 1)
      }

      const prods = this.optionsTarget.find(item => item.product_child === p.logo)?.targets

      const handleUncheck = (prodArray, v, c) => {
        const targetProd = prods.find(prod => prodArray.includes(prod.value))
        if (targetProd) {
          prodArray.forEach(targetType => {
            const foundTarget = prods.find(t => t.value === targetType)
            if (foundTarget) foundTarget.checked = false
          })
        }
      }

      if (p.value === 'komplace') {
        handleUncheck(this.komplaceProds)
      }
      if (p.value === 'komship') {
        handleUncheck(this.komshipProds)
      }
      if (p.value === 'komcards') {
        handleUncheck(this.komcardsProds)
      }
      if (p.value === 'raja_ongkir') {
        handleUncheck(this.komcardsProds)
      }

      this.totalTargets = this.selectedTarget.reduce((acc, item) => acc + item.count, 0)
    },

    handleProductName(value) {
      if (value === 'Raja Ongkir') {
        return 'raja-ongkir'
      } return value
    },

    handleLableName(value) {
      if (value === 'Komship Juragan Saudagar') {
        return 'Komship Juragan Saudagar All'
      } if (value === 'Komship Regular') {
        return 'Komship Regular All'
      } if (value === 'Raja Ongkir Starter') {
        return 'Starter'
      } if (value === 'Raja Ongkir Pro') {
        return 'Pro'
      } if (value === 'Raja Ongkir Enterprise') {
        return 'Enterprise'
      } if (value === 'Raja Ongkir') {
        return 'RO OpenAPI'
      }
      return value
    },

  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
@import '~quill/dist/quill.snow.css';

.vs__dropdown-menu {
  max-height: 230px;
}
.vs__deselect {
  display: none !important;
}
.dropdownitem {
  .btn {
    display: flex;
    justify-content: space-between;
  }
}
.collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }

.bg-red {
  background-color: #f5532e;
  color: white;
  width: fit-content;
}
</style>
